@font-face {
  font-family: "iconfont"; /* Project id 4372831 */
  src: url('iconfont.woff2?t=1721198822986') format('woff2'),
       url('iconfont.woff?t=1721198822986') format('woff'),
       url('iconfont.ttf?t=1721198822986') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-double-down:before {
  content: "\e66a";
}

.icon-dingwei:before {
  content: "\e6a2";
}

.icon-a-rongqi2:before {
  content: "\e68c";
}

.icon-exe:before {
  content: "\e68a";
}

.icon-prescription:before {
  content: "\e68b";
}

.icon-system-admn:before {
  content: "\e66b";
}

.icon-report-set:before {
  content: "\e66c";
}

.icon-a-xingbie11:before {
  content: "\e65f";
}

.icon-xingbie:before {
  content: "\e65e";
}

.icon-download:before {
  content: "\e655";
}

.icon-project:before {
  content: "\e65c";
}

.icon-school:before {
  content: "\e65d";
}

.icon-person:before {
  content: "\e653";
}

.icon-statistic:before {
  content: "\e654";
}

.icon-about-system:before {
  content: "\e656";
}

.icon-device:before {
  content: "\e657";
}

.icon-examine-history:before {
  content: "\e658";
}

.icon-logs:before {
  content: "\e659";
}

.icon-user:before {
  content: "\e65a";
}

.icon-student:before {
  content: "\e65b";
}

.icon-dayu:before {
  content: "\e641";
}

.icon-dabaoxue:before {
  content: "\e642";
}

.icon-yujiaxue:before {
  content: "\e643";
}

.icon-xiaoxue:before {
  content: "\e644";
}

.icon-a-zhenxueyewan:before {
  content: "\e645";
}

.icon-duoyun:before {
  content: "\e646";
}

.icon-daxue:before {
  content: "\e647";
}

.icon-xiaoyu:before {
  content: "\e648";
}

.icon-yin:before {
  content: "\e649";
}

.icon-lei:before {
  content: "\e64a";
}

.icon-taifeng:before {
  content: "\e64b";
}

.icon-leiyubingbao:before {
  content: "\e64c";
}

.icon-dabaoyu:before {
  content: "\e64d";
}

.icon-baoxue:before {
  content: "\e64e";
}

.icon-baoyu:before {
  content: "\e64f";
}

.icon-xue-copy:before {
  content: "\e651";
}

.icon-yun:before {
  content: "\e652";
}

.icon-yu:before {
  content: "\e650";
}

.icon-shachen:before {
  content: "\e633";
}

.icon-fuchen:before {
  content: "\e62d";
}

.icon-shachenbao:before {
  content: "\e634";
}

.icon-qing:before {
  content: "\e635";
}

.icon-leibaodayu:before {
  content: "\e636";
}

.icon-bingbao:before {
  content: "\e637";
}

.icon-wu:before {
  content: "\e638";
}

.icon-dongyu:before {
  content: "\e639";
}

.icon-feng:before {
  content: "\e63a";
}

.icon-a-qingyewan:before {
  content: "\e63b";
}

.icon-a-duoyunyewan:before {
  content: "\e63c";
}

.icon-a-zhenyuyewan:before {
  content: "\e63d";
}

.icon-zhenxue:before {
  content: "\e63e";
}

.icon-zhongyu:before {
  content: "\e63f";
}

.icon-mai:before {
  content: "\e640";
}

.icon-zhenyu:before {
  content: "\e631";
}

.icon-tedabaoxue:before {
  content: "\e62f";
}

.icon-zhongxue:before {
  content: "\e630";
}

.icon-qiangshachenbao:before {
  content: "\e62e";
}

.icon-tedabaoyu:before {
  content: "\e632";
}

